import React from 'react'
import styled from 'styled-components'
import { Link as HistoryLink } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { RowBetween } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import useI18n from 'hooks/useI18n'

const Tabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
`

export function FindPoolTabs() {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
          {/* 不知道这个干啥的，反正也就是个头部，目前不知道谁的 */}
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool22222222222222222222</ActiveText>
        <QuestionHelper
          text={TranslateString(256, 'Use this tool to find pairs that do not automatically appear in the interface.')}
        />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
          {/* 添加流动性详情页面header，向左箭头 */}
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
          {/* 添加流动性详情页面header中间文字 */}
        <ActiveText>{adding ? TranslateString(258, 'Add') : TranslateString(260, 'Remove')} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? TranslateString(
                  264,
                  'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
                )
              : TranslateString(
                  266,
                  'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
                )
          }
        />
      </RowBetween>
    </Tabs>
  )
}
